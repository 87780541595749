// import { Entity } from '@backstage/catalog-model';
import { createApiRef } from '@backstage/core-plugin-api';
import { RestEndpointMethodTypes } from '@octokit/rest';
import { destroyInfraParams } from '../models/destroyInfraParams';

export interface IacmanagerApi {
    startWorkflowRun(hostname:string, githubRepoSlug: string, workflowId: number, branch: string, inputs?: {[key: string]: unknown}): Promise<RestEndpointMethodTypes['actions']['createWorkflowDispatch']['response']['status']>;
    destroyInfra(githubHost: string, repoSlug: string, branch: string, inputsParamsState: destroyInfraParams): Promise<Boolean>;
    listWorkflows(hostname: string,githubRepoSlug: string, filter?: string[]): Promise<RestEndpointMethodTypes['actions']['listRepoWorkflows']['response']['data']['workflows']>;
  }
  export const iacManagerApiRef = createApiRef<IacmanagerApi>({
    id: 'plugin.menu-actions',
  });
  