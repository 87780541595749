import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './logo/LogoFull';
import LogoIcon from './logo/LogoIcon';
import CategoryIcon from '@material-ui/icons/Category';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  GroupIcon,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import CloudIcon from '@material-ui/icons/Cloud';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
// import { KialiIcon } from '@janus-idp/backstage-plugin-kiali';
import CardTravel from '@mui/icons-material/CardTravel';
import BuildIcon from '@material-ui/icons/Build';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import TextsmsOutlined  from '@material-ui/icons/TextsmsOutlined';
import { useApi, configApiRef, IconComponent, useApp } from '@backstage/core-plugin-api';


const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    // marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const deploymentEnv = useApi(configApiRef).getString('backend.deploymentEnv');
  const templateFilters = useApi(configApiRef).getOptionalConfigArray('catalog.templateFilters');
  return (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={HomeIcon} to="/" text="Home" />

        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
          <SidebarSubmenu title="Catalog">
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=domain"
              icon={useApp().getSystemIcon('kind:domain')}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={useApp().getSystemIcon('kind:system')}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={useApp().getSystemIcon('kind:component')}
            />
            <SidebarSubmenuItem
              title="APIs"
              to="catalog?filters[kind]=api"
              icon={useApp().getSystemIcon('kind:api')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource"
              icon={useApp().getSystemIcon('kind:resource')}
            />
            <SidebarSubmenuItem
              title="Templates"
              to="catalog?filters[kind]=template"
              icon={useApp().getSystemIcon('kind:template')}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Groups"
              to="catalog?filters[kind]=group"
              icon={useApp().getSystemIcon('kind:group')}
            />
            <SidebarSubmenuItem
              title="Users"
              to="catalog?filters[kind]=user"
              icon={useApp().getSystemIcon('kind:user')}
            />
          </SidebarSubmenu>
        </SidebarItem>

        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create...">
          <SidebarSubmenu title="Environment">
          {templateFilters?.map((filter, index) => (
            <SidebarSubmenuItem
              key={index}
              title={filter.getString('name')}
              to={`create?filters[kind]=template&filters[tags]=${filter.getString('value')}`}
              icon={CloudIcon}
            />
          ))}
          </SidebarSubmenu>
        </SidebarItem>

        {/* End global nav */}
        <SidebarDivider />
        {((deploymentEnv === 'aws') || (deploymentEnv === 'oc') || (deploymentEnv === 'segittur')) && (
          <SidebarItem icon={MoneyIcon} to="cost-insights" text="Cloud Costs"/>
        )}
        <SidebarDivider />

        <SidebarItem icon={CategoryIcon} to="devtools" text="DevTools">
          <SidebarSubmenu title="DevTools">
            <SidebarSubmenuItem icon={MapIcon} to="tech-radar" title="Tech Radar" />
            <SidebarItem icon={CardTravel as unknown as IconComponent} to="toolbox" text="ToolBox" />
            <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
          </SidebarSubmenu>
        </SidebarItem>

      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarItem icon={LiveHelpIcon} to="qeta" text="FAQ" />
      <SidebarItem icon={TextsmsOutlined} to="announcements" text="Announcements" />
      {/* <SidebarSpace /> */}
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />


      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
  )
};
