import { ScmAuthApi } from '@backstage/integration-react';
import DismissIcon from '@material-ui/icons/Delete';
import { ConfigApi, createApiRef, IconComponent } from '@backstage/core-plugin-api';
import { Octokit, RestEndpointMethodTypes } from "@octokit/rest";
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { readGithubIntegrationConfigs } from "@backstage/integration";
import { Entity } from '@backstage/catalog-model';
import { IacmanagerApi } from './IacManagerApi';
import { destroyInfraParams } from '../models/destroyInfraParams';


class Client {
  private readonly configApi: ConfigApi;
  private readonly catalogApi: CatalogApi;
  private readonly scmAuthApi: ScmAuthApi;
  // public extraMenuActions: ExtraContextMenuItem[];

  constructor(configApi: ConfigApi, catalogApi: CatalogApi, scmAuthApi: ScmAuthApi) {
    this.configApi = configApi;
    this.catalogApi = catalogApi;
    this.scmAuthApi = scmAuthApi;
  }

  private async getOctokit(hostname: string = 'github.com'):Promise<Octokit>{
    const { token } = await this.scmAuthApi.getCredentials({
      url: `https://${hostname}/`,
      additionalScope: {
        customScopes: {
          github: ['repo'],
        },
      },
    });
    const configs = readGithubIntegrationConfigs(
      this.configApi.getOptionalConfigArray('integrations.github') ?? [],
    );
    const githubIntegrationConfig = configs.find(v => v.host === hostname);
    const baseUrl = githubIntegrationConfig?.apiBaseUrl;
    return new Octokit({ auth: token, baseUrl });
  }

  async listWorkflows(hostname: string,githubRepoSlug: string, filter?: string[]): Promise<RestEndpointMethodTypes['actions']['listRepoWorkflows']['response']['data']['workflows']>{
    const octokit = await this.getOctokit(hostname);
    const {owner, repo} = this.parseRepo(githubRepoSlug);
    const response = await octokit.actions.listRepoWorkflows({
      owner,
      repo
    });
    if(!filter || filter.length === 0) return response.data.workflows;
    const filteredWorkflows = response.data.workflows.filter(workflow => 
      filter.includes(this.regexFileName(workflow.path))
    )
    return filteredWorkflows;
  };

  async startWorkflowRun(hostname:string, githubRepoSlug: string, workflowId: number, branch: string, inputs?: {[key: string]: unknown}): Promise<RestEndpointMethodTypes['actions']['createWorkflowDispatch']['response']['status']>{
    const octokit = await this.getOctokit(hostname);
    const { owner, repo } = this.parseRepo(githubRepoSlug);
    const inputsParams = inputs || {};
    
    const totalWorkflowRunsBefore = await this.listWorkflowRunsTotalCount(
      hostname,
      githubRepoSlug,
      workflowId,
    );
    let totalWorkflowRunsAfter = totalWorkflowRunsBefore;
    const loadTime = 1500;

    const response = await octokit.actions.createWorkflowDispatch({
      owner,
      repo,
      workflow_id: workflowId,
      ref: branch,
      inputs: inputsParams
    }); 

    while (totalWorkflowRunsAfter === totalWorkflowRunsBefore) {
      await this.waitTime(loadTime);
      totalWorkflowRunsAfter = await this.listWorkflowRunsTotalCount(
        hostname,
        githubRepoSlug,
        workflowId,
      );
    }

    return response.status;
  }

  async listWorkflowRunsTotalCount(hostname:string, githubRepoSlug:string, workflowId:number):Promise<RestEndpointMethodTypes['actions']['listWorkflowRuns']['response']['data']['total_count']>{
    const octokit = await this.getOctokit(hostname);
    const { owner, repo } = this.parseRepo(githubRepoSlug);
    const response = await octokit.actions.listWorkflowRuns({
      owner,
      repo,
      workflow_id: workflowId
    });
    return response.data.total_count
  }

  async destroyInfra(hostname: string, projectName: string, workFlowId: number, branch: string, inputsParamsState: WorkflowInputs): Promise<Boolean> {
    const response = await this.startWorkflowRun(hostname, projectName, workFlowId,branch, inputsParamsState as any);
    if(response === 204) return true;
    return false;
  }

  private parseRepo(githubRepoSlug:string) : {repo:string, owner: string}{
    const parse = githubRepoSlug.split('/');
    return {owner: parse[0], repo: parse[1]}
  }
  private regexFileName(input: string) {
    const fileName = input.match(/(?:[\w\d\-\.](?!\/))+$/) ?? ""
    return fileName[0]
  }
  private async waitTime(time: number) {
    return await new Promise(r => setTimeout(r, time));
  }
}

export class IacManagerClient implements IacmanagerApi{
    
  private readonly client: Client;
  constructor(configApi: ConfigApi, catalogApi: CatalogApi, scmAuthApi: ScmAuthApi) {
    this.client = new Client(configApi, catalogApi, scmAuthApi);
  }

  startWorkflowRun(hostname: string, githubRepoSlug: string, workflowId: number, branch: string, inputs?: {[key: string]: unknown}): Promise<RestEndpointMethodTypes['actions']['createWorkflowDispatch']['response']['status']> {
    return this.client.startWorkflowRun(hostname, githubRepoSlug, workflowId, branch, inputs);
  }

  async destroyInfra(githubHost: string, repoSlug: string, branch: string, inputsParamsState: destroyInfraParams): Promise<Boolean> {
    const workflowId = (await this.client.listWorkflows(githubHost, repoSlug, ['delete_feature.yml']))[0].id;
    if(!workflowId) throw new Error('No workflow found for terraform destroy');
    return this.client.destroyInfra(githubHost, repoSlug, workflowId, branch, inputsParamsState);
  }
  
  listWorkflows(hostname: string, githubRepoSlug: string, filter?: string[]): Promise<RestEndpointMethodTypes['actions']['listRepoWorkflows']['response']['data']['workflows']> {
    return this.client.listWorkflows(hostname, githubRepoSlug, filter);
  }
}