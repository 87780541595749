import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import { rootRouteRef } from './routes';
import { iacManagerApiRef } from './api/IacManagerApi';
import { IacManagerClient } from './api/IacManagerClient';

export const iacManagerTsystemsPlugin = createPlugin({
  id: 'iac-manager-tsystems',
  routes: { 
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: iacManagerApiRef,
      deps: {
        configApi: configApiRef, 
        discoveryApi: discoveryApiRef, 
        catalogApi: catalogApiRef, 
        scmAuthApi: scmAuthApiRef
      },
      factory: ({ configApi, discoveryApi, catalogApi, scmAuthApi }) => {
        return new IacManagerClient(configApi, catalogApi, scmAuthApi);
      },
    }),
  ],
});

export const IacManagerTsystemsPage = iacManagerTsystemsPlugin.provide(
  createRoutableExtension({
    name: 'IacManagerTsystemsPage',
    component: () =>
      import('./components/IacManagerContent').then(m => m.IacManagerContent),
    mountPoint: rootRouteRef,
  }),
);
