import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 130,
  },
  path: {
    fill: '#e20074',
  },
  g:{
    fill: '#e20074',
  }
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    // <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 38"><path className={classes.path} d="M7.6 25.1H0v-7.6h7.6v7.6ZM0 0v12.9h2.3v-.4c0-6.1 3.4-9.9 9.9-9.9h.4V30c0 3.8-1.5 5.3-5.3 5.3H6.1V38h19.8v-2.7h-1.1c-3.8 0-5.3-1.5-5.3-5.3V2.7h.4c6.5 0 9.9 3.8 9.9 9.9v.4h2.3V0H0Zm24.3 25.1h7.6v-7.6h-7.6v7.6Z"/></svg>

    <svg 
    className={classes.svg}
    version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="3000.000000pt" height="2000.000000pt" viewBox="0 0 3000.000000 2000.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
    fill="#e20074" stroke="none">
    <path d="M11085 12139 c-310 -41 -582 -254 -688 -539 -50 -132 -62 -212 -61
    -395 1 -201 17 -276 90 -424 39 -78 62 -110 133 -182 146 -147 294 -228 641
    -349 329 -115 451 -166 528 -220 188 -132 269 -308 259 -565 -3 -100 -9 -135
    -34 -205 -88 -255 -295 -400 -573 -400 -205 0 -373 72 -525 225 -181 181 -287
    418 -381 853 -4 21 -9 22 -89 22 l-85 0 0 -605 0 -605 84 0 84 0 30 53 c55 95
    111 132 182 116 19 -4 93 -42 164 -84 199 -116 317 -153 512 -162 143 -7 253
    7 371 47 289 97 532 370 621 697 24 91 26 112 26 298 0 189 -2 205 -28 290
    -76 249 -225 421 -472 548 -138 70 -231 108 -507 208 -360 131 -493 210 -579
    344 -61 93 -83 178 -82 315 1 139 26 223 98 325 101 141 252 215 436 215 172
    0 312 -56 439 -176 167 -157 266 -356 346 -696 l28 -118 73 0 74 0 0 550 0
    550 -70 0 -69 0 -35 -53 c-42 -67 -80 -97 -119 -97 -19 0 -79 27 -160 72 -141
    78 -223 111 -330 134 -90 19 -245 25 -332 13z"/>
    <path d="M3507 11793 c-4 -153 -10 -413 -13 -579 l-7 -301 78 -7 c42 -4 79 -5
    82 -2 3 2 11 53 18 113 22 172 53 299 102 418 123 297 387 465 732 465 l81 0
    0 -1317 c0 -835 -4 -1351 -10 -1407 -23 -195 -108 -256 -362 -256 l-118 0 0
    -85 0 -85 745 0 745 0 0 85 0 85 -110 0 c-216 0 -314 43 -361 160 -12 29 -14
    261 -17 1429 l-2 1394 112 -6 c145 -7 243 -29 343 -78 233 -112 377 -364 454
    -798 12 -63 23 -116 25 -118 2 -2 40 -1 86 3 l82 7 -6 166 c-3 91 -9 352 -12
    579 l-7 412 -1327 0 -1327 0 -6 -277z"/>
    <path d="M17220 11901 c0 -6 -7 -61 -15 -124 -21 -164 -58 -297 -115 -413 -41
    -85 -60 -111 -117 -163 -78 -72 -189 -130 -310 -163 l-83 -23 0 -72 0 -73 180
    0 180 0 0 -727 c0 -702 8 -952 31 -1051 20 -84 77 -189 134 -247 94 -96 232
    -145 415 -145 182 0 303 43 406 145 123 121 184 313 184 576 l0 119 -83 0 -84
    0 -6 -113 c-10 -187 -51 -341 -111 -420 -14 -19 -49 -46 -77 -61 -45 -25 -59
    -28 -124 -24 -94 4 -146 37 -188 119 -58 113 -59 137 -64 1022 l-4 807 335 0
    336 0 0 85 0 85 -335 0 -335 0 0 435 0 435 -75 0 c-41 0 -75 -4 -75 -9z"/>
    <path d="M19070 11109 c-165 -17 -308 -83 -438 -204 -186 -173 -302 -398 -354
    -685 -24 -135 -29 -440 -9 -580 60 -430 244 -728 536 -870 129 -62 201 -80
    355 -87 199 -9 346 29 497 130 78 52 192 164 257 252 41 56 146 232 146 245 0
    7 -134 80 -148 80 -6 0 -33 -42 -60 -93 -135 -251 -287 -379 -484 -409 -264
    -40 -475 106 -566 392 -41 126 -72 360 -72 536 l0 94 681 0 680 0 -5 43 c-3
    23 -8 76 -11 117 -26 318 -145 608 -329 799 -179 187 -405 267 -676 240z m241
    -206 c161 -72 269 -296 298 -623 6 -63 11 -132 11 -152 l0 -38 -445 0 c-444 0
    -445 0 -445 21 0 75 26 279 45 355 66 261 168 403 325 455 42 14 161 4 211
    -18z"/>
    <path d="M15461 11080 c-186 -39 -345 -146 -429 -289 -53 -90 -74 -168 -79
    -292 -13 -271 88 -458 320 -595 82 -49 136 -71 442 -180 230 -82 321 -136 386
    -230 148 -215 39 -536 -207 -610 -104 -31 -296 -7 -403 52 -172 93 -289 261
    -380 541 l-38 118 -77 3 -76 3 0 -426 0 -425 78 0 77 0 13 43 c20 63 50 107
    75 107 11 0 64 -25 116 -55 210 -119 280 -139 496 -140 184 0 242 11 371 75
    151 74 279 221 336 385 85 248 49 514 -95 690 -119 146 -243 213 -651 355
    -219 75 -296 112 -353 168 -67 64 -88 119 -87 222 0 67 5 95 23 132 36 75 95
    134 169 170 64 32 71 33 177 32 85 -1 123 -6 167 -22 129 -47 226 -138 298
    -282 40 -79 83 -206 95 -277 l7 -43 79 0 79 0 0 365 0 365 -78 0 -79 0 -12
    -40 c-6 -22 -22 -47 -35 -55 -22 -14 -30 -12 -147 44 -68 33 -151 67 -184 76
    -70 20 -326 29 -394 15z"/>
    <path d="M21398 11074 c-149 -36 -292 -148 -384 -302 l-34 -56 0 183 0 184
    -72 -6 c-40 -4 -200 -12 -355 -19 l-283 -12 0 -82 0 -81 83 -5 c95 -5 138 -24
    173 -76 46 -68 49 -117 49 -912 0 -679 -2 -751 -18 -814 -9 -37 -26 -81 -37
    -96 -28 -39 -91 -62 -192 -68 l-88 -5 0 -78 0 -79 545 0 545 0 0 79 0 78 -85
    5 c-106 7 -172 36 -197 88 -36 73 -40 162 -35 758 4 633 5 644 68 801 61 153
    154 260 258 296 53 18 139 20 193 3 56 -17 132 -88 162 -153 61 -129 60 -98
    63 -1067 l4 -888 379 0 380 0 0 79 0 78 -83 5 c-52 4 -101 13 -129 26 -100 44
    -109 89 -115 607 -6 480 6 781 37 900 45 178 153 335 268 392 48 24 69 28 137
    28 92 0 137 -19 196 -82 110 -115 119 -214 119 -1265 l0 -768 370 0 370 0 0
    80 0 80 -50 0 c-83 0 -149 19 -187 54 -65 60 -66 62 -73 821 -9 793 -11 815
    -91 983 -73 153 -211 263 -384 307 -70 18 -222 20 -297 4 -170 -36 -324 -161
    -435 -352 l-39 -67 -49 98 c-83 165 -213 276 -372 317 -71 19 -238 18 -315 -1z"/>
    <path d="M24412 11071 c-229 -58 -401 -222 -457 -436 -22 -80 -22 -270 0 -350
    50 -187 177 -327 387 -424 45 -22 164 -67 263 -101 330 -113 454 -190 511
    -314 78 -172 42 -352 -98 -481 -81 -76 -161 -100 -297 -92 -139 8 -239 46
    -344 132 -118 96 -201 237 -278 468 l-41 127 -74 0 -74 0 0 -425 0 -425 76 0
    c69 0 76 2 81 21 3 12 18 44 32 73 34 66 53 69 139 18 198 -117 301 -151 477
    -159 259 -12 435 49 589 203 83 83 136 168 173 279 21 66 27 104 30 215 8 234
    -39 362 -188 512 -78 79 -97 92 -210 146 -68 33 -218 93 -334 132 -339 116
    -416 162 -470 281 -24 55 -27 70 -23 143 5 96 33 159 100 223 154 149 433 134
    609 -34 98 -93 169 -232 214 -420 l18 -73 78 0 79 0 0 365 0 365 -79 0 -78 0
    -18 -45 c-27 -73 -42 -75 -153 -17 -163 84 -241 104 -417 108 -121 3 -164 0
    -223 -15z"/>
    <path d="M12510 10961 l0 -78 73 -5 c124 -8 178 -51 243 -194 14 -32 185 -485
    379 -1006 l353 -947 -48 -133 c-121 -340 -196 -465 -321 -532 -72 -40 -167
    -47 -232 -18 -53 24 -59 37 -19 45 41 8 93 47 126 95 26 37 31 55 34 117 5 89
    -9 135 -57 188 -50 56 -109 80 -196 80 -86 0 -146 -24 -194 -78 -90 -102 -103
    -261 -33 -403 33 -67 120 -155 187 -188 139 -68 319 -68 463 1 135 64 252 211
    346 434 25 58 231 608 459 1221 395 1064 444 1189 489 1253 28 40 88 67 148
    67 l50 0 0 80 0 80 -415 0 -415 0 0 -80 0 -80 93 0 c114 0 162 -13 204 -55 28
    -28 33 -40 33 -82 0 -27 -7 -74 -16 -104 -23 -78 -444 -1253 -452 -1261 -9 -9
    -518 1323 -527 1378 -5 35 -2 46 19 71 32 38 84 53 185 53 l81 0 0 80 0 80
    -520 0 -520 0 0 -79z"/>
    <path d="M3460 10260 l0 -300 295 0 295 0 0 300 0 300 -295 0 -295 0 0 -300z"/>
    <path d="M5562 10263 l3 -298 298 -3 297 -2 0 300 0 300 -300 0 -300 0 2 -297z"/>
    <path d="M7370 10260 l0 -300 300 0 300 0 0 300 0 300 -300 0 -300 0 0 -300z"/>
    <path d="M9180 10260 l0 -300 300 0 300 0 0 300 0 300 -300 0 -300 0 0 -300z"/>
    <path d="M25950 10260 l0 -300 295 0 295 0 0 300 0 300 -295 0 -295 0 0 -300z"/>
    </g>
    </svg>
  );
};

export default LogoFull;
