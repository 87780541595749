import React, { useState } from 'react';
import { Card, Icon, InputAdornment, TextField } from '@material-ui/core';
import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { useAsync } from 'react-use';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { FieldValidation } from '@rjsf/utils';
import CardTravel from '@mui/icons-material/CardTravel';
import SegmentIcon from '@mui/icons-material/Segment';
import LinkIcon from '@mui/icons-material/Link';
import { templateFieldsApiRef } from '../api';

const DynamicPickWithLinkWithOptionsFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    form_data: z
      .string()
      .describe('Endpoint from form-data plugin to fill the select')
      .optional(),
    external_data: z
      .string()
      .describe('External endpoint to fill the select. Needs to return a JSON array of strings.')
      .optional(),
    no_options_text: z
      .string()
      .default('No options')
      .describe('Text to show when there are no options available'),
  }),
);
export const DynamicPickWithLinkWithOptionsSchema = DynamicPickWithLinkWithOptionsFieldSchema.schema;


type DynamicPickWithLinkWithOptionsProps = typeof DynamicPickWithLinkWithOptionsFieldSchema.type;

export const DynamicPickWithLink = ({
  onChange,
  rawErrors,
  required,
  formData,
  idSchema,
  schema: { title, description },
  uiSchema: { 'ui:options': options },
}: DynamicPickWithLinkWithOptionsProps) => {
  const config = useApi(configApiRef);
  const api = useApi(templateFieldsApiRef);
  const backendUrl = config.getString('backend.baseUrl');
  const [loading, setLoading] = useState(true);
  const [formDataOptions, setFormDataOptions] = useState<Option[]>([]);

  interface Option {
    name: string;
    link: string;
  }

  useAsync(async () => {
    if (options?.external_data) {
      const data = await (await fetch(options?.external_data)).json();
      console.log(formDataOptions, formData, rawErrors, await (await fetch(options?.external_data)).json());
      setFormDataOptions(data);
      setLoading(false);
    } else if (options?.form_data) {
      const value = await api.get(`${backendUrl}/api/${options?.form_data}`);
      console.log(formDataOptions, formData, rawErrors, value);
      setFormDataOptions(value);
      setLoading(false);
    }
  });

  const handleIconClick = (url:string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Autocomplete
      id={idSchema?.$id}
      loading={loading}
      value={formDataOptions.find(option => {
        console.log('Current option:', option, formData);
        return option.name.toLowerCase() === formData?.toLowerCase();
      }) ?? undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          variant="standard"
          required={required}
          error={rawErrors?.length > 0 && !formData}
          helperText={description}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SegmentIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      options={formDataOptions}
      onChange={(_, value) => onChange(value?.name ?? '')}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      noOptionsText={options?.no_options_text}
      disableClearable
      renderOption={(option: Option) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LinkIcon
            style={{ marginRight: 8, cursor: 'pointer' }}
            onClick={() => handleIconClick(option.link)}
          />
          {option.name}
        </div>
      )}
    />
  )
};

export const validationDynamicPickWithLink = (
  value: string,
  validation: FieldValidation,
) => {
  const endpointRegex = /^[^/][\w.-]*$/;

  if (typeof value !== 'string') {
    validation.addError('Selected item should be a string.');
  } else if (!endpointRegex.test(value)) {
    validation.addError('The endpoint should not start with a "/" and should be a valid string.');
  }
};