import { createApiFactory, createPlugin, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { DynamicPickExtension, DynamicPickExtensionWithOptionsSchema, validationDynamicPickExtension } from './components/DynamicPickExtension';
import { DynamicPickWithLink, DynamicPickWithLinkWithOptionsSchema, validationDynamicPickWithLink } from './components/DynamicPickWithLink';
import { TemplateFieldsApiClient, templateFieldsApiRef } from './api';

export const templateFieldsTsystemsPlugin = createPlugin({
  id: 'template-fields-tsystems',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: templateFieldsApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) => {
        return new TemplateFieldsApiClient({ discoveryApi, fetchApi })
      },
    })
  ],
});

export const DynamicPickFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
      name: 'DynamicPickExtension',
      component: DynamicPickExtension,
      schema: DynamicPickExtensionWithOptionsSchema,
      validation: validationDynamicPickExtension
  }),
);

export const DynamicPickFieldWithLink = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
      name: 'DynamicPickWithLink',
      component: DynamicPickWithLink,
      schema: DynamicPickWithLinkWithOptionsSchema,
      validation: validationDynamicPickWithLink
  }),
);
