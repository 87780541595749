import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { useAsync } from 'react-use';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { FieldValidation } from '@rjsf/utils';
import { templateFieldsApiRef } from '../api';

const DynamicPickExtensionWithOptionsFieldSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    form_data: z
      .string()
      .describe('Endpoint from form-data plugin to fill the select')
      .optional(),
    external_data: z
      .string()
      .describe('External endpoint to fill the select. Needs to return a JSON array of strings.')
      .optional(),
    no_options_text: z
      .string()
      .default('No options')
      .describe('Text to show when there are no options available'),
  }),
);
export const DynamicPickExtensionWithOptionsSchema = DynamicPickExtensionWithOptionsFieldSchema.schema;


type DynamicPickExtensionWithOptionsProps = typeof DynamicPickExtensionWithOptionsFieldSchema.type;

export const DynamicPickExtension = ({
  onChange,
  rawErrors,
  required,
  formData,
  idSchema,
  schema: { title, description },
  uiSchema: { 'ui:options': options },
}: DynamicPickExtensionWithOptionsProps) => {
  const config = useApi(configApiRef);
  const api = useApi(templateFieldsApiRef);
  const backendUrl = config.getString('backend.baseUrl');
  const [loading, setLoading] = useState(true);
  const [formDataOptions, setFormDataOptions] = useState([]);

  useAsync(async () => {
    if (options?.external_data) {
      setFormDataOptions(await (await fetch(options?.external_data)).json());
      console.log(formDataOptions, formData, rawErrors, await (await fetch(options?.external_data)).json());
      setLoading(false);
    } else if (options?.form_data) {
      const value = await api.get(`${backendUrl}/api/${options?.form_data}`);
      console.log(formDataOptions, formData, rawErrors, value);
      setFormDataOptions(value);
      setLoading(false);
    }
  });

  return (
    <Autocomplete
      id={idSchema?.$id}
      loading={loading}
      value={formData ?? undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          variant="standard"
          required={required}
          error={rawErrors?.length > 0 && (!formData )}
          // error={!formData}
          // error={rawErrors?.length > 0 && (!formData || (formData?.length && formData?.length===0))}
          helperText={description}
        />
      )}
      options={formDataOptions}
      onChange={(_, value) => onChange(value)}
      getOptionSelected={(option, value) => option === value}
      noOptionsText={options?.no_options_text}
      disableClearable
    />
  )
};

export const validationDynamicPickExtension = (
  value: string,
  validation: FieldValidation,
) => {
  const endpointRegex = /^[^/][\w.-]*$/;

  if (typeof value !== 'string') {
    validation.addError('Selected item should be a string.');
  } else if (!endpointRegex.test(value)) {
    validation.addError('The endpoint should not start with a "/" and should be a valid string.');
  }
};