import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntitySonarQubeCard, EntitySonarQubeContentPage, isSonarQubeAvailable } from '@backstage-community/plugin-sonarqube';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  isPulumiAvailable,
  EntityPulumiCard,
  PulumiComponent
} from '@pulumi/backstage-plugin-pulumi';
import {
  isGitlabAvailable,
  EntityGitlabContent,
  EntityGitlabPipelinesTable,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import {
  isTektonCIAvailable,
  TektonCI,
} from '@janus-idp/backstage-plugin-tekton';
import { NexusRepositoryManagerPage } from '@janus-idp/backstage-plugin-nexus-repository-manager';
import {
  EntityArgoCDContent,
  isArgocdAvailable,
} from '@roadiehq/backstage-plugin-argo-cd';
import {
  HarborPage,
  HarborWidget,
  isHarborAvailable,
} from '@digitalist-open-cloud/backstage-plugin-harbor';
import { EntityKubernetesContent , isKubernetesAvailable } from '@backstage/plugin-kubernetes';
import { EntityCostInsightsContent } from '@internal/plugin-cost-insights-tsystems';
import { EntityGrafanaAlertsCard, EntityGrafanaDashboardsCard, EntityOverviewDashboardViewer, isOverviewDashboardAvailable } from '@backstage-community/plugin-grafana';
import { EntityJenkinsContent, isJenkinsAvailable } from '@backstage-community/plugin-jenkins';
import { GithubWorkflowsContent, isGithubWorkflowsAvailable } from '@internal/plugin-github-workflows-tsystems';
import { QetaContent } from './QetaContent';
import { IacManagerTsystemsPage } from '@internal/backstage-plugin-iac-manager-tsystems';

const isNexusAvailable = (entity: Entity): boolean => {
  const annotations = entity.metadata?.annotations;
  if (annotations) {
    const keys = Object.keys(annotations);
    return keys.some(key => key.includes('nexus-repository-manager'));
  }
  return false;
};

const isInfraAvailable = (entity: Entity): boolean => {
  const annotations = entity.metadata?.annotations;
  if (annotations) {
    const keys = Object.keys(annotations);
    return keys.some(key => key.includes('project.iac'));
  }
  return false;
};

const isCostInsightsAvailable = (entity: Entity): boolean => {
  return entity.metadata?.cloudCosts ? true : false;
};

const isGrafanaAlertsAvailable = (entity: Entity): boolean => {
  if (entity.metadata.annotations && entity.metadata.annotations['grafana/alert-label-selector']) {
    console.log('grafana',entity.metadata.annotations['grafana/alert-label-selector'])
    return true;
  }
  return false;
};

const isGrafanaOverviewAvailable = (entity: Entity): boolean => {
  console.log('grafana',entity)

  if (entity.metadata.annotations && entity.metadata.annotations['grafana/overview-dashboard']) {
    console.log('grafana',entity.metadata.annotations['grafana/overview-dashboard'])
    return true;
  }
  return false;
};

const isGrafanaAvailable = (entity: Entity): boolean => {
  if (entity.metadata.annotations) {
    const annotationKeys = Object.keys(entity.metadata.annotations);
    if (annotationKeys.some(key => key.includes('grafana'))) {
      return true;
    }
  }
  return false;
};


const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const pulumiContent = (
  <EntitySwitch renderMultipleMatches="all">
      <EntitySwitch.Case if={isPulumiAvailable}>
          <PulumiComponent/>
      </EntitySwitch.Case>
  </EntitySwitch>
);

const cicdContent = (
  <EntitySwitch renderMultipleMatches="all">

    <EntitySwitch.Case if={isGithubWorkflowsAvailable}>
      <GithubWorkflowsContent/>
    </EntitySwitch.Case>
    
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabPipelinesTable />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isJenkinsAvailable}>
      <EntityJenkinsContent />
      {/* <EntityLatestJenkinsRunCard branch="main,master,develop,development" variant="gridItem"/> */}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isTektonCIAvailable}>
      <TektonCI />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={(_entity, _context) => !isGithubActionsAvailable && !isGitlabAvailable && !isJenkinsAvailable && !isTektonCIAvailable}>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const grafanaContent = (
  <EntitySwitch renderMultipleMatches="all">
    <EntitySwitch.Case if={isOverviewDashboardAvailable}>
      <EntityGrafanaDashboardsCard/>
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isGrafanaAlertsAvailable}>
      <EntityGrafanaAlertsCard />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isGrafanaOverviewAvailable}>
      <EntityOverviewDashboardViewer  />
    </EntitySwitch.Case>

    <EntitySwitch.Case if={(_entity, _context) => !isOverviewDashboardAvailable}>
      <EmptyState
        title="No Grafana Dashboards available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable Grafana Dashboards for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://github.com/backstage/community-plugins/blob/ef29bc2b92a8944fbc30172fc683d161bf18e4dc/workspaces/grafana/plugins/grafana/docs/dashboards-on-component-page.md"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);


const entityWarningContent = (
  <EntitySwitch renderMultipleMatches="all">
    <EntitySwitch.Case if={isOrphan}>
      <Grid item xs={12}>
        <EntityOrphanWarning />
      </Grid>
    </EntitySwitch.Case>

    <EntitySwitch.Case if={hasRelationWarnings}>
      <Grid item xs={12}>
        <EntityRelationWarning />
      </Grid>
    </EntitySwitch.Case>

    <EntitySwitch.Case if={hasCatalogProcessingErrors}>
      <Grid item xs={12}>
        <EntityProcessingErrorsPanel />
      </Grid>
    </EntitySwitch.Case>
  </EntitySwitch>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>

    <Grid item md={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    
    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    
    <EntitySwitch renderMultipleMatches="all">
      <EntitySwitch.Case if={isSonarQubeAvailable}>
        <Grid item sm={6}>
          <EntitySonarQubeCard variant="gridItem" />
        </Grid>
      </EntitySwitch.Case>

      <EntitySwitch.Case if={isPulumiAvailable}>
          <Grid item md={6}>
              <EntityPulumiCard />
          </Grid>
      </EntitySwitch.Case>

      <EntitySwitch.Case if={isHarborAvailable}>
        <Grid item md={6}>
          <HarborWidget />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/gitlab" title="Gitlab" if={isGitlabAvailable}>
      <EntityGitlabContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/argocd" title="ArgoCD" if={e =>  isArgocdAvailable (e) }>
      <EntityArgoCDContent /> 
    </EntityLayout.Route>

    <EntityLayout.Route path="/sonarqube" title="Sonar" if={e =>  isSonarQubeAvailable (e) }>
      <EntitySonarQubeContentPage /> 
    </EntityLayout.Route>

    <EntityLayout.Route path="/pulumi" title="Pulumi" if={isPulumiAvailable}>
      {pulumiContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/harbor" title="Harbor" if={isHarborAvailable}>
      <>
        <HarborPage />
        <HarborWidget />
      </>
    </EntityLayout.Route>

    <EntityLayout.Route if={isGrafanaAvailable} path="/grafana" title="Grafana">
      {grafanaContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isNexusAvailable} path="/build-artifacts" title="Nexus">
      <NexusRepositoryManagerPage />
    </EntityLayout.Route>


    <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    {/* < EntityLayout.Route if={isAmazonEcsServiceAvailable} path = "/ecs" title = "Amazon ECS" >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route> */}

    < EntityLayout.Route if={isCostInsightsAvailable} path = "/cloud-costs" title = "Cloud Costs" >
      <EntityCostInsightsContent />
    </EntityLayout.Route>
    
    < EntityLayout.Route if={isInfraAvailable} path = "/infra" title = "Cloud infra" >
      <IacManagerTsystemsPage />
    </EntityLayout.Route>
    
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/qeta" title="Q&A">
      <QetaContent />
    </EntityLayout.Route>

  </EntityLayout>
);

const libraryEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/gitlab" title="Gitlab" if={isGitlabAvailable}>
      <EntityGitlabContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/argocd" title="ArgoCD" if={e =>  isArgocdAvailable (e) }>
      <EntityArgoCDContent /> 
    </EntityLayout.Route>

    <EntityLayout.Route path="/sonarqube" title="Sonar" if={e =>  isSonarQubeAvailable (e) }>
      <EntitySonarQubeContentPage /> 
    </EntityLayout.Route>

    <EntityLayout.Route path="/pulumi" title="Pulumi" if={isPulumiAvailable}>
      {pulumiContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/harbor" title="Harbor" if={isHarborAvailable}>
      <>
        <HarborPage />
        <HarborWidget />
      </>
    </EntityLayout.Route>

    <EntityLayout.Route if={isGrafanaAvailable} path="/grafana" title="Grafana">
      {grafanaContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isNexusAvailable} path="/build-artifacts" title="Nexus">
      <NexusRepositoryManagerPage />
    </EntityLayout.Route>

    <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    {/* < EntityLayout.Route if={isAmazonEcsServiceAvailable} path = "/ecs" title = "Amazon ECS" >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route> */}

    < EntityLayout.Route if={isCostInsightsAvailable} path = "/cloud-costs" title = "Cloud Costs" >
      <EntityCostInsightsContent />
    </EntityLayout.Route>

    < EntityLayout.Route if={isInfraAvailable} path = "/infra" title = "Cloud infra" >
      <IacManagerTsystemsPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/qeta" title="Q&A">
      <QetaContent />
    </EntityLayout.Route>

  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/gitlab" title="Gitlab" if={isGitlabAvailable}>
      <EntityGitlabContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/argocd" title="ArgoCD" if={e =>  isArgocdAvailable (e) }>
      <EntityArgoCDContent /> 
    </EntityLayout.Route>

    <EntityLayout.Route path="/sonarqube" title="Sonar" if={e =>  isSonarQubeAvailable (e) }>
      <EntitySonarQubeContentPage /> 
    </EntityLayout.Route>

    <EntityLayout.Route path="/pulumi" title="Pulumi" if={isPulumiAvailable}>
      {pulumiContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/harbor" title="Harbor" if={isHarborAvailable}>
      <>
        <HarborPage />
        <HarborWidget />
      </>
    </EntityLayout.Route>

    <EntityLayout.Route if={isGrafanaAvailable} path="/grafana" title="Grafana">
      {grafanaContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isNexusAvailable} path="/build-artifacts" title="Nexus">
      <NexusRepositoryManagerPage />
    </EntityLayout.Route>

    <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>

    {/* < EntityLayout.Route if={isAmazonEcsServiceAvailable} path = "/ecs" title = "Amazon ECS" >
      <EntityAmazonEcsServicesContent />
    </EntityLayout.Route> */}

    < EntityLayout.Route if={isCostInsightsAvailable} path = "/cloud-costs" title = "Cloud Costs" >
      <EntityCostInsightsContent />
    </EntityLayout.Route>

    < EntityLayout.Route if={isInfraAvailable} path = "/infra" title = "Cloud infra" >
      <IacManagerTsystemsPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/qeta" title="Q&A">
      <QetaContent />
    </EntityLayout.Route>

  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('library')}>
      {libraryEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/qeta" title="Q&A">
      <QetaContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
        {/* <EntitySwitch>
          <EntitySwitch.Case if={isPulumiAvailable}>
              <Grid item md={6}>
                  <EntityPulumiMetdataCard/>
              </Grid>
          </EntitySwitch.Case>
        </EntitySwitch> */}
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
