import { genPageTheme, shapes, createBaseThemeOptions, createUnifiedTheme, palettes } from '@backstage/theme';
// import './../fonts/fonts.css';

export const DarkTheme = createUnifiedTheme({ 
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#e20074',
      },
      secondary: {
        main: '#565a6e',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#34548a',
      },
      success: {
        main: '#485e30',
      },
      background: {
        default: '#333333',
        paper: '#424242',
      },
      banner: {
        // info: '#34548a',
        info: '#4d5191',
        error: '#8c4351',
        text: '#d5d6db',
        link: '#f0ab00',
      },
      errorBackground: '#424242',
      warningBackground: '#424242',
      infoBackground: '#424242',
      navigation: {
        background: '#424242',
        indicator: '#e20074',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#e20074', '#2a2a2a'], shape: shapes.wave }),
    documentation: genPageTheme({colors: ['#e20074', '#2a2a2a'],shape: shapes.wave2}),
    tool: genPageTheme({ colors: ['#e20074', '#2a2a2a'], shape: shapes.round }),
    service: genPageTheme({colors: ['#b53592', '#2a2a2a'],shape: shapes.wave}),
    website: genPageTheme({colors: ['#804a9c', '#2a2a2a'],shape: shapes.wave}),
    library: genPageTheme({colors: ['#4d5191', '#2a2a2a'],shape: shapes.wave}),
    resource: genPageTheme({ colors: ['#2e5077', '#2a2a2a'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#2f4858', '#2a2a2a'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#804a9c', '#2a2a2a'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#b53592', '#2a2a2a'], shape: shapes.wave }),
    system: genPageTheme({ colors: ['#2e5077', '#2a2a2a'], shape: shapes.wave }),
  },
});
